/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'repeat-1': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 4v1.466a.25.25 0 00.41.192l2.36-1.966a.25.25 0 000-.384l-2.36-1.966a.25.25 0 00-.41.192V3H5a5 5 0 00-4.48 7.223.5.5 0 00.896-.446A4 4 0 015 4zm4.48 1.777a.5.5 0 00-.896.446A4 4 0 0111 12H5.001v-1.466a.25.25 0 00-.41-.192l-2.36 1.966a.25.25 0 000 .384l2.36 1.966a.25.25 0 00.41-.192V13h6a5 5 0 004.48-7.223z"/><path pid="1" d="M9 5.5a.5.5 0 00-.854-.354l-1.75 1.75a.5.5 0 10.708.708L8 6.707V10.5a.5.5 0 001 0z"/>',
    },
});
